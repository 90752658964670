import { User, UserExt, UserRegistDto,Menus } from '@/models/user'
import { defineStore } from 'pinia'
import storage from '@/utils/storage'
import { UserClass } from '@/apis'
import { dataToPromiseTree, filterAsyncRouter } from '@/utils'
import { normalRoutes, roleRoutes } from '@/configs/route.config'
import { RouteRecordRaw } from 'vue-router'
import { BreadcrumbRoute } from '@/models/types'

const userApi = new UserClass()
export const useUserStore = defineStore('user', {
  state: () => {
    return {
      token: '',
      userInfo: null as User | null,
      userExt: null as UserExt | null,
      menus: <Array<Menus>>[],
      routers: <Array<RouteRecordRaw>>[],
      roleRouters: <Array<RouteRecordRaw>>[],
      breadcrumbRoutes: <Array<BreadcrumbRoute>>[]
    }
  },
  getters: {},
  actions: {
    setUserInfo(user: User){
      this.userInfo = user
    },

    setToken(token: string){
      if(token){
        this.token = token
        storage.set('BEARER_TOKEN', token)
      }
    },

    onLogout(){
      this.token = ''
      this.userInfo = null
      this.menus = []
      storage.remove('BEARER_TOKEN')
    },

    onLogin(username: string, password: string){
      return new Promise((resolve, reject) => {
        userApi.login(username, password).then((resp) => {

          this.setToken(resp.token)
          
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onCheckUserInIot(){
      return new Promise((resolve, reject) => {
        userApi.checkUserInIot().then((resp) => {          
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onRegist(data: UserRegistDto){
      return new Promise((resolve, reject) => {
        userApi.regist(data).then((resp) => {
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onGetUserInfo(){
      return new Promise((resolve, reject) => {
        userApi.info().then((resp) => {
          this.setUserInfo(resp)
          resolve(resp)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onGetMenus(){
      return new Promise<Menus[]>((resolve, reject) => {
        userApi.menus().then((res) => {
          console.log(res)
          if(res.length === 0){
            res = [
              {
                id: -1,
                name: '首页',
                sign: 'index',
                roleId: 0,
                type: 0,
                parentId: 0,
                sort: 0,
                remark: 'string',
                actionList: []
              }
            ]
          }

          this.menus = res
          const pros = dataToPromiseTree(res, 0)
          resolve(pros)
        }).catch(e => {
          reject(e)
        })
      })
    },

    GenerateRoutes(permissionMenus: Menus[]){
      return new Promise((resolve, reject) => {
        const menus = permissionMenus

        const accessedRouters = filterAsyncRouter(roleRoutes, menus)

        this.roleRouters = accessedRouters
        this.routers = normalRoutes.concat(accessedRouters)
        resolve(accessedRouters)
      })
    },

    onGetSms(mobile: string){
      return new Promise((resolve, reject) => {
        userApi.getSms(mobile).then((res) => {
          console.log(res)
          resolve(true)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onCheckSms(mobile: string, code: string){
      return new Promise((resolve, reject) => {
        userApi.checkSms(mobile, code).then((res) => {
          console.log(res)
          resolve(true)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onGetUserExt(){
      return new Promise<UserExt>((resolve, reject) => {
        userApi.userExt().then((res: UserExt) => {
          this.userExt = res
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },

    onUpdateUserMqttPsw(password: string){
      return new Promise<number>((resolve, reject) => {
        userApi.modifyMqttAccount(password).then((res: number) => {
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },

    getKeyHex(){
      return new Promise<string>((resolve, reject) => {
        userApi.keyHex().then((res: string) => {
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
})