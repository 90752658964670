import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../assets/avatar.png'


const _hoisted_1 = { class: "basic-layout" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "user-name" }
const _hoisted_7 = { class: "user-avatar" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  src: _imports_0,
  alt: ""
}
const _hoisted_10 = { class: "body" }
const _hoisted_11 = { class: "body-view" }
const _hoisted_12 = { class: "news-content" }
const _hoisted_13 = { class: "modal-info" }
const _hoisted_14 = { class: "username" }
const _hoisted_15 = { class: "userid" }
const _hoisted_16 = { class: "small" }
const _hoisted_17 = { class: "modal-info" }

import defaultConfig from '@/configs/default.config'
import { useUserStore } from '@/store'
import { message } from 'ant-design-vue'
import { CopyOutlined } from '@ant-design/icons-vue'
import router from '@/router'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { BreadcrumbRoute } from '@/models/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsLayout',
  setup(__props) {

const userStore = useUserStore()
const route = useRoute()

const updateRoute = () => {
  const matched = route.matched
  const routeList:BreadcrumbRoute[] = []

  matched.filter(x => x.name !== 'iot-control').map((e) => {
    routeList.push({
      path: e.path,
      title: (e.path === '/' ? '控制台' : e.meta.title) as string
    })
  })

  userStore.breadcrumbRoutes = routeList
}

watch(route, () => {
  console.log('路由变化')
  updateRoute()
}, { immediate: true })

const userInfo = userStore.userInfo
const { project_title,project_logo } = defaultConfig
const showModal = ref(false)

const onCopy = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    message.success('已复制到粘贴板')
  } catch (err) {
    message.error('复制失败：' + err)
  }
}

const onLogout = () => {
  userStore.onLogout()
  message.success('退出登录成功')
  router.push({ name: 'iot-login' })
}

const inModal = ref(false)
const onModalMouseleave = () => {
  showModal.value = false
  inModal.value = false
}
const onModalMouseenter = () => {
  inModal.value = true
}
const onDropLeave = () => {
  setTimeout(() => {
    if (!inModal.value) {
      showModal.value = false
      inModal.value = false
    }
  }, 100)
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "logo"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _unref(project_logo),
              alt: ""
            }, null, 8, _hoisted_4),
            _createTextVNode(" " + _toDisplayString(_unref(project_title)), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "userinfo",
          onMouseenter: _cache[0] || (_cache[0] = () => showModal.value = true),
          onMouseleave: onDropLeave
        }, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(userInfo)?.username), 1),
          _createElementVNode("div", _hoisted_7, [
            (_unref(userInfo)?.avatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _unref(userInfo)?.avatar,
                  alt: ""
                }, null, 8, _hoisted_8))
              : (_openBlock(), _createElementBlock("img", _hoisted_9))
          ])
        ], 32)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_router_view)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["userinfo-modal", {
      'show': showModal.value
    }]),
      onMouseleave: onModalMouseleave,
      onMouseenter: onModalMouseenter
    }, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(userInfo)?.username), 1),
        _createElementVNode("div", _hoisted_15, [
          _cache[3] || (_cache[3] = _createTextVNode(" KEY: ")),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(userInfo)?.id), 1),
          _createVNode(_component_a_tooltip, { placement: "bottom" }, {
            title: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("复制")
            ])),
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: "",
                class: "copy",
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (onCopy(_unref(userInfo)?.id)), ["prevent","stop"]))
              }, [
                _createVNode(_unref(CopyOutlined))
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_router_link, {
          to: "",
          class: "link-menu"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("账号信息")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "",
          class: "link-menu"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("实名认证")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "",
          class: "link-menu"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("安全设置")
          ])),
          _: 1
        })
      ]),
      _createVNode(_component_a_button, {
        block: "",
        danger: "",
        onClick: onLogout
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("退出登录")
        ])),
        _: 1
      })
    ], 34)
  ]))
}
}

})